import React, { useContext, useEffect, useState } from "react";

import { HeaderParameters } from "../data/Header-Context";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTitle,
} from "@ionic/react";
import { toast } from "react-toastify";

//CustomElements
import CustomPage from "./utils/CustomElements/CustomPage";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
//data
import UserContext from "../data/AuthContextProvider";
import {
  getProductBaseLineData,
  getProductsShort,
  getTargetType,
  insertIntoBaseLine,
  updateProductTarget,
  updateProductsTargetsFromBaseLine,
  updateTargetType,
} from "../services/DataServices";

const Targets: React.FC = () => {
  const { authValues } = useContext(UserContext);
  const [products, setProducts] = useState<any>([]);
  const [productBaseLineData, setProductBaseLineData] = useState<any>([]);
  const [selectedTarget, setSelectedTarget] = useState<any>("");

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };
  const columns = [
    {
      title: "שם המוצר",
      field: "name",
      cellStyle: {
        width: "80%",
        minWidth: "200px",
        textAlign: "right",
      },
      editable: false,
    },
    {
      title: "יעד",
      field: "target",
      cellStyle: {
        width: "20%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "numeric",
      filtering: false,
    },
  ];
  const columnsProductBaseLine = [
    {
      title: "שם המוצר",
      field: "productName",
      cellStyle: {
        width: "15%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "תאריך הכנסה אחרון",
      field: "LastInsertDate",
      cellStyle: {
        width: "15%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "date",
    },
    {
      title: "מתאריך",
      field: "FromDate",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "date",
    },
    {
      title: "עד תאריך",
      field: "ToDate",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "date",
    },
    {
      title: "תקציב שהוקצה",
      field: "AvgIncome",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "קליקים",
      field: "AvgClicks",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "חשיפות",
      field: "AvgImpression",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgCtr",
      field: "AvgCtr",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "MinROI",
      field: "MinROI",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "MaxROI",
      field: "MaxROI",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "תקציב שנוצל",
      field: "AvgCost",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "המרות",
      field: "AvgConversions",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgPageArrived",
      field: "AvgPageArrived",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "AvgShoppingCart",
      field: "AvgShoppingCart",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "number",
    },
  ];

  const refresh = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getProductsShort(orgId).then((result) => {
      setProducts(result);
    });
  };

  const refreshProductBaseLine = () => {
    getProductBaseLineData(authValues?.OrgId!).then((result) => {
      setProductBaseLineData(undefined);
      setProductBaseLineData(result);
    });
  };

  const saveRow = (selectedRow: any) => {
    console.log(selectedRow);
    updateProductTarget(selectedRow.runid, selectedRow.target)
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה עודכנה בהצלחה");
          refresh();
        } else {
          toast.warning("לא נמצאו רשומות לעדכון");
        }
      })
      .catch(() => {
        toast.error("שגיאה בעדכון רשומה");
      });
  };

  const insertToBaseLine = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    insertIntoBaseLine(orgId)
      .then((result) => {
        console.log(result);
        if (result !== -1) {
          if (result !== 0) {
            toast.info(result + " רשומות נוספו בהצלחה!");
            refreshProductBaseLine();
          } else {
            toast.warning("רשומות לא נוספו להיום");
          }
        } else {
          toast.error("שגיאה בהוספת רשומות!!!");
        }
      })
      .catch(() => {
        toast.error("שגיאה בהוספת רשומות!!!");
      });
  };
  const updateProductsTargets = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    updateProductsTargetsFromBaseLine(orgId)
      .then((result) => {
        console.log(result);
        if (result == 0) {
          toast.warning("רשומות לא עודכנו להיום");
        } else {
          toast.info(result + " רשומות עודכנו בהצלחה!");
          refresh();
        }
      })
      .catch(() => {
        toast.error("שגיאה בעדכון רשומות!!!");
      });
  };

  useEffect(() => {
    refresh();
    refreshProductBaseLine();
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getTargetType(orgId).then((result) => {
      setSelectedTarget(result);
    });
  }, []);

  return (
    <CustomPage
      headerParameters={headerProps}
      pageName={"Targets"}
      title="יעדים"
    >
      <IonGrid>
        <IonCard>
          <IonRow>
            <IonCol size="8">
              <IonTitle>
                <span
                  style={{
                    display: "inline-block",
                    marginTop: "15px",
                  }}
                >
                  מוצרים ויעדים
                </span>
                <IonButton
                  onClick={updateProductsTargets}
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  עדכן יעדים
                </IonButton>
              </IonTitle>

              {authValues && authValues.Id && (
                <CustomMaterialTable
                  rows={products}
                  columns={columns}
                  title={""}
                  loaderDuration={3000}
                  isDateRange={false}
                  isEditable={true}
                  onEdit={saveRow}
                  isFilterable={true}
                  isDeleteHidden={true}
                />
              )}
            </IonCol>
            <IonCol
              style={{
                marginRight: "50px",
              }}
              size="3"
            >
              <IonTitle>סוג יעד</IonTitle>
              <IonRadioGroup
                value={selectedTarget}
                onIonChange={(e) => {
                  if (selectedTarget) {
                    setSelectedTarget(e.detail.value);
                    const orgId =
                      authValues && authValues.OrgId ? authValues.OrgId : "";
                    updateTargetType(e.detail.value, orgId)
                      .then((result) => {
                        if (result === "Success") {
                          toast.info("סוג יעד נשמר בהצלחה!");
                        } else {
                          toast.warning("סוג יעד לא נשמר.");
                        }
                      })
                      .catch(() => {
                        toast.error("שגיאה בשמירת סוג היעד");
                      });
                  }
                }}
              >
                <IonItem lines="full" mode="md" key={1}>
                  <IonLabel>המרות</IonLabel>
                  <IonRadio slot="start" value={1} />
                </IonItem>
                <IonItem lines="full" mode="md" key={2}>
                  <IonLabel>עלות לפרסום </IonLabel>
                  <IonRadio slot="start" value={2} />
                </IonItem>
              </IonRadioGroup>
            </IonCol>
          </IonRow>
        </IonCard>

        <IonCard>
          <IonRow className="ion-no-margin ion-no-padding">
            <IonCol style={{ direction: "ltr" }}>
              <IonButton onClick={insertToBaseLine}>
                הכנס בוחן ביצועים אחרון
              </IonButton>
            </IonCol>
          </IonRow>
          {productBaseLineData && (
            <CustomMaterialTable
              rows={productBaseLineData}
              columns={columnsProductBaseLine}
              title={"בוחן ביצועים אחרון עבור כל מוצר"}
              loaderDuration={3000}
              isDateRange={false}
              isFilterable={false}
              pageSize={productBaseLineData.length}
            />
          )}
        </IonCard>
      </IonGrid>
    </CustomPage>
  );
};

export default Targets;
