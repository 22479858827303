const prod = {
  url: {
    API_URL: "http://ws.blackdog.co.il",
    //API_URL: "http://blackDogWS.b-soft.co.il",
    Member_Url: "https://members.b144.co.il",
    MEMBERS_LOGIN_CHECK_URL:
      "https://members.b144.co.il/Infrastructure/B144_Login/checkuserlogin.aspx?tp=1&_t=", //tp - UserType regular : 1 ; debug :2
  },
  adminId: 43,
};
const dev = {
  url: {
    API_URL: "http://localhost:3000",
    Member_Url: "http://localhost:55309",
    MEMBERS_LOGIN_CHECK_URL:
      "http://localhost:55309/Infrastructure/B144_Login/checkuserlogin.aspx?tp=1&_t=", //tp - UserType regular : 1 ; debug :2
  },
  adminId: 2,
};

export const config = process.env.NODE_ENV === "production" ? prod : dev;
