import React, { useContext, useEffect, useState } from "react";

//data
import { HeaderParameters } from "../data/Header-Context";
import UserContext from "../data/AuthContextProvider";
import { getAlgorithmRows } from "../services/DataServices";
//Elements
import CustomPage from "./utils/CustomElements/CustomPage";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";

const Algorithm: React.FC = () => {
  const { authValues } = useContext(UserContext);
  const [algorithmRows, setAlgorithmRows] = useState<any>([]);

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  const columns = [
    {
      title: "תאריך הדגימה",
      field: "fromDate",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
      type: "datetime",
    },
    // {
    //   title: "סטטוס נוכחי",
    //   field: "status",
    //   cellStyle: {
    //     width: "16%",
    //     minWidth: "100px",
    //     textAlign: "right",
    //   },
    // },
    {
      title: "קליקים",
      field: "clicks",
      cellStyle: {
        width: "10%",
        minWidth: "50px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "המרות",
      field: "conversions",
      cellStyle: {
        width: "10%",
        minWidth: "50px",
        textAlign: "right",
      },
      type: "number",
    },
    {
      title: "ממצא",
      field: "finding",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "אבחון",
      field: "diagnosis",
      cellStyle: {
        // width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "פעולת עדכון נדרשת",
      field: "action_desc_heb",
      cellStyle: {
        // width: "10%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "שם קמפיין",
      field: "campaign_name",
      cellStyle: {
        width: "20%",
        minWidth: "400px",
        textAlign: "right",
      },
    },
    {
      title: "שם מוצר",
      field: "product_name",
      cellStyle: {
        width: "20%",
        minWidth: "300px",
        textAlign: "right",
      },
    },
  ];
  const refresh = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getAlgorithmRows(orgId).then((result) => {
      console.log(result);
      setAlgorithmRows(undefined);
      setAlgorithmRows(result);
    });
  };

  useEffect(() => {
    refresh();
  }, []);
  return (
    <CustomPage
      headerParameters={headerProps}
      pageName={"Algorithm"}
      title="אלגוריתם"
    >
      {algorithmRows && (
        <CustomMaterialTable
          rows={algorithmRows}
          columns={columns}
          title={"אלגוריתם"}
          loaderDuration={3000}
          isDateRange={false}
          isEditable={false}
          isFilterable={false}
          pageSize={algorithmRows.length}
        />
      )}
    </CustomPage>
  );
};
export default Algorithm;
