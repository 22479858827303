import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { NavLink } from "react-router-dom";
import { addCircleOutline, calendarOutline } from "ionicons/icons";

//data
import { HeaderParameters } from "../data/Header-Context";
import UserContext from "../data/Auth-context";
import { getProductsPerformance } from "../services/DataServices";
//elements
import CustomPage from "./utils/CustomElements/CustomPage";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import {
  getDateFormat,
  getDateReverseFormat,
} from "../utils-ts/GlobalDateFunctions";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
import CustomDateRangePicker from "./utils/CustomElements/CustomDateRangePicker/CustomDateRangePicker";

import Cookies from "universal-cookie";
import moment from "moment";

const ProductsPerformance: React.FC = () => {
  const [productsPerformance, setProductsPerformance] = useState<any>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const [isDateRangeShow, setDateRangeShow] = useState<boolean>(false);

  const { authValues, logout } = useContext(UserContext);

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
    authValues: authValues,
  };

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };

  const columns = [
    {
      title: "",
      field: "runId",
      cellStyle: {
        width: "50px",
        textAlign: "center",
        minWidth: "50px",
      },
      render: (row: any) => {
        return (
          <NavLink
            to={{
              pathname: `/Campaigns/${row.runId}`,
            }}
          >
            <IonIcon
              size="large"
              icon={addCircleOutline}
              slot="end"
              // onClick={() => {
              //   getCampaigns(row);
              // }}
            />
          </NavLink>
        );
      },
      filtering: false,
    },

    {
      title: "שם המוצר",
      field: "productName",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "תקציב שהוקצה",
      field: "income",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "תקציב שנוצל",
      field: "cost",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "המרות",
      field: "conversions",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "חשיפות",
      field: "impressions",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "עלות ממוצעת להמרה",
      field: "costConv",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
    {
      title: "כניסות לנכסים דיגיטליים",
      field: "entriesToDigitalAssets",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      filtering: false,
    },
  ];

  const cookies = new Cookies();

  useEffect(() => {
    if (
      cookies.get("selectedStartDate") != undefined &&
      cookies.get("selectedEndDate") != undefined
    ) {
      const fromDate: Date = moment(cookies.get("selectedStartDate")).toDate();
      const endDate: Date = moment(cookies.get("selectedEndDate")).toDate();
      setSelectedStartDate(fromDate);
      setSelectedEndDate(endDate);
    } else {
      setSelectedStartDate(new Date());
      setSelectedEndDate(new Date());
    }
  }, []);
  useEffect(() => {
    if (
      authValues?.OrgId! &&
      selectedStartDate != undefined &&
      selectedEndDate != undefined
    ) {
      getProductsPerformance(
        getDateReverseFormat(selectedStartDate),
        getDateReverseFormat(selectedEndDate),
        authValues?.OrgId!
      ).then((result) => {
        setProductsPerformance(undefined);
        setProductsPerformance(result);
      });
    }

    cookies.set("selectedStartDate", selectedStartDate);
    cookies.set("selectedEndDate", selectedEndDate);
  }, [selectedStartDate, selectedEndDate]);
  return (
    <CustomPage
      headerParameters={headerProps}
      //   title="ביצועי מוצרים"
      pageName={"ProductsPerformance"}
      authValues={authValues}
      fixedPage={true}
    >
      <IonGrid className="card-container">
        <IonRow className="ion-align-items-center">
          <IonCol className="ion-no-margin ion-no-padding card-title-container bold-text large-title ">
            ביצועי מוצרים
            <IonButton
              size="small"
              className="date-range-picker-btn"
              onClick={() => {
                setDateRangeShow(true);
              }}
            >
              <IonIcon size="small" icon={calendarOutline} slot="end" />
              {selectedEndDate != undefined && selectedStartDate != undefined && (
                <IonLabel>
                  {getDateFormat(selectedEndDate)} -
                  {getDateFormat(selectedStartDate)}
                </IonLabel>
              )}
            </IonButton>
          </IonCol>
        </IonRow>
        {productsPerformance && (
          <CustomMaterialTable
            rows={productsPerformance}
            columns={columns}
            title={""}
            loaderDuration={3000}
            isDateRange={false}
            isFilterable={true}
            pageSize={productsPerformance.length}
            className="homeGrid"
          />
        )}
      </IonGrid>

      <CustomModal
        pageName={"DateRangePicker"}
        headerParameters={headerParameters}
        closeModal={() => {
          setDateRangeShow(false);
        }}
        isOpen={isDateRangeShow}
        title="בחר טווח תאריכים"
      >
        <CustomDateRangePicker
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onSearchClick={(startDate: Date, endDate: Date) => {
            setDateRangeShow(false);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
          }}
        ></CustomDateRangePicker>
      </CustomModal>
    </CustomPage>
  );
};

export default ProductsPerformance;
