import React, { useRef, useState } from "react";

import { IonGrid, IonRow, IonCol } from "@ionic/react";
import UserContext from "../../data/AuthContextProvider";
import CustomAlert, {
  ButtonProperties,
} from "../../components/utils/CustomElements/CustomAlert";
import { HeaderParameters } from "../../data/Header-Context";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import "./login.scss";
import CustomForm from "../../components/utils/CustomElements/CustomForm";
import CustomPublishButton from "../../components/utils/CustomElements/CustomPublishButton";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/utils/CustomElements/CustomInput";
import { getNotificationToken } from "../../firebaseInit";
const Login: React.FC = () => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const userCtx = React.useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const emailRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);

  const loginHandler = async () => {
    getNotificationToken()
      .then((firebaseToken) => {
        loginWithNotificationToken(firebaseToken);
      })
      .catch((err) => {
        loginWithNotificationToken("");
      });
  };

  const loginWithNotificationToken = async (firebaseToken: any) => {
    let res = await userCtx.login(
      emailRef.current!.value!.toString(),
      passwordRef.current!.value!.toString(),
      firebaseToken
    );

    if (res != "") {
      setError(res);
      setShowAlert(true);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Tab" && passwordRef != null) {
      e.preventDefault();
      passwordRef.current!.setFocus();
    }
  };

  const ErrorMessageButton: ButtonProperties[] = [
    {
      text: "אישור",
      role: "ok",
      handler: () => {
        setShowAlert(false);
        setError("");
        window.location.reload(false);
      },
    },
  ];

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isPageHaveChanges: false,
    isShowHamburger: false,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: true,
  };
  return (
    <CustomPage
      headerParameters={headerProps}
      title="ברוכים הבאים לאפליקציית Black Dog"
      subTitle={"כניסה למערכת"}
      pageName=""
    >
      <CustomForm onSubmit={handleSubmit(loginHandler)} errors={errors}>
        <CustomAlert
          isOpen={showAlert}
          header={"הודעת מערכת"}
          message={error}
          onDismiss={() => {
            setShowAlert(false);
            setError("");
            window.location.reload(false);
          }}
          buttons={ErrorMessageButton}
        ></CustomAlert>
        <IonGrid
          fixed={true}
          className="login-wrapper"
          style={{ marginTop: "-35px" }}
        >
          <IonRow>
            <IonCol>
              <CustomInput
                name="phonNum"
                label="מייל"
                errors={errors}
                control={control}
                type="text"
                value=""
                required={true}
                ref={emailRef}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomInput
                name="password"
                label="סיסמה"
                errors={errors}
                control={control}
                type="password"
                value=""
                required={true}
                ref={passwordRef}
              />
            </IonCol>
          </IonRow>
          <IonRow className="actions-wrapper">
            <IonCol>
              <CustomPublishButton
                expand="block"
                className="main-button"
                title="כניסה"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomPage>
  );
};
export default Login;
