import { FieldError, NestDataObject } from "react-hook-form";

export const validateForm = (
  errors: NestDataObject<Record<string, any>, FieldError>
) => {
  if (Object.keys(errors).length > 0) {
    let key = Object.keys(errors).reduceRight((k, l) => {
      return document.getElementsByName(k)[0].offsetTop <
        document.getElementsByName(l)[0].offsetTop
        ? k
        : l;
    });
    document.getElementsByName(key)[1].focus();
    return false;
  } else {
    return true;
  }
};

export const validatePatterns = (value: string, type: string) => {
  let pattern = undefined;
  switch (type) {
    case "facebook":
      pattern =
        /(?:https?:\/\/)?(?:www\.)?(?:he-il\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-._:A-Za-z0-9&%?=א-ת]*\/)*([\w\-\._:A-Za-z0-9&%?=א-ת]*)/;
      if (!pattern.test(value)) {
        return "כתובת פייסבוק אינה תקינה";
      }
      break;
    case "youtube":
      pattern =
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
      if (!pattern.test(value)) {
        return "כתובת youtube אינה תקינה";
      }
      break;
    case "email":
      pattern =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (!pattern.test(value)) {
        return "כתובת מייל אינה תקינה";
      }
      break;
    case "tel":
      pattern = /[0-9]{9}/;
      if (!pattern.test(value)) {
        return "טלפון אינו תקין";
      }
      break;
    case "url":
      pattern = /(http:\/\/|https:){1}?.?\S*$/;
      if (!pattern.test(value)) {
        return "יש להזין כתובת תקינה לדוגמא: https://www.b144.co.il";
      }
      break;
    case "password":
      pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!pattern.test(value)) {
        //return "יש להציג סיסמה עם מינימום שש ספרות ולפחות אות אחת באנגלית וספרה";
      }
      break;
  }
};

export const ErrorMessage = (key: string, parameter?: string) => {
  switch (key) {
    case "required":
      return "שדה חובה";
      break;
    case "maxLength":
      return " מס התווים המקסימלי הוא " + parameter;
      break;
    case "minLength":
      return " מס התווים המינימלי הוא " + parameter;
      break;
    default:
      return "שדה לא תקין";
      break;
  }
};
