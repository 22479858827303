import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { toast } from "react-toastify";

import { read, utils } from "xlsx";

import { HeaderParameters } from "../data/Header-Context";
//CustomElements
import CustomPage from "./utils/CustomElements/CustomPage";
import CustomMaterialTable from "./utils/CustomElements/CustomDataGrid/CustomMaterialTable";
import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
import CustomPublishButton from "./utils/CustomElements/CustomPublishButton";
import CustomForm from "./utils/CustomElements/CustomForm";
//data
import {
  getProducts,
  updateProduct,
  updateProductInStock,
  addProduct,
  deleteProduct,
  loadProducts,
  insertRequestToProduct,
  checkIfHasRequestToProduct,
  addCampaign,
  insertRequestToChangeStatusCampaign,
  getAccounts,
} from "../services/DataServices";
import UserContext from "../data/AuthContextProvider";

const Products: React.FC = () => {
  const { authValues } = useContext(UserContext);

  const [products, setProducts] = useState<any>([]);
  const [inStock, setInStock] = useState<string>("1");
  const [data, setData] = React.useState<any>(null);
  const [accounts, setAccounts] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>("");
  const [selectedProduct, setSelectedProduct] = useState<any>("");
  const [chooseAccountShow, setChooseAccountShow] = useState<boolean>(false);

  const { handleSubmit, formState, errors, control, register } = useForm({
    mode: "onChange",
  });

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isShowGreeting: true,
    isPageHaveChanges: false,
    isShowHamburger: true,
    isShowBackButton: false,
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: false,
  };

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };

  const columns = [
    {
      title: "שם המוצר",
      field: "name",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "",
      field: "productId",
      cellStyle: {
        width: "16%",
        minWidth: "70px",
        textAlign: "right",
      },
      editable: false,
      render: (row: any) => {
        return (
          <button
            style={{
              width: "100%",
              height: "30px",
              fontWeight: "bold",
              borderRadius: "8px",
            }}
            onClick={() => {
              setSelectedProduct(row);
              setChooseAccountShow(true);
            }}
            disabled={row.cnt > 0}
          >
            העלאה
          </button>
        );
      },
    },
    {
      title: "",
      field: "runid",
      cellStyle: {
        width: "16%",
        minWidth: "70px",
        textAlign: "right",
      },
      editable: false,
      render: (row: any) => {
        return (
          <button
            style={{
              width: "100%",
              height: "30px",
              fontWeight: "bold",
              borderRadius: "8px",
            }}
            onClick={() => {
              stopProduct(row);
            }}
            disabled={row.cnt == 0 || row.status == 2}
          >
            עצירת מוצר
          </button>
        );
      },
    },

    {
      title: "מס' מוצר",
      field: "productId",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      //editable: false,
      type: "numeric",
    },
    {
      title: "קטגוריה",
      field: "category",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "לוקיישן",
      field: "location",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "כותרת",
      field: "title",
      cellStyle: {
        width: "16%",
        minWidth: "200px",
        textAlign: "right",
      },
    },
    {
      title: "כותרת ארוכה",
      field: "longTitle",
      cellStyle: {
        width: "16%",
        minWidth: "300px",
        textAlign: "right",
      },
    },
    {
      title: "תיאור",
      field: "description",
      cellStyle: {
        width: "16%",
        minWidth: "300px",
        textAlign: "right",
      },
    },
    {
      title: "מחיר",
      field: "price",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      type: "numeric",
    },
    {
      title: "תמונת לוגו",
      field: "logoImage",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      editable: false,
    },
    {
      title: "URL",
      field: "finalUrl",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
    },
    {
      title: "במלאי",
      field: "inStock",
      cellStyle: {
        width: "16%",
        minWidth: "100px",
        textAlign: "right",
      },
      render: (row: any) => {
        return <div>{row.inStock == 1 ? "כן" : "לא"}</div>;
      },
      editComponent: (row: any) => {
        return (
          <div>
            <div>
              <input
                type="radio"
                id="option1"
                name="inStock"
                value="1"
                checked={row.rowData.inStock}
                onChange={(event) =>
                  inStockChange(event.target.value, row.rowData.runid)
                }
              />
              <label htmlFor="option1">כן</label>
            </div>
            <div>
              <input
                type="radio"
                id="option2"
                name="inStock"
                value="0"
                checked={!row.rowData.inStock}
                onChange={(event) =>
                  inStockChange(event.target.value, row.rowData.productId)
                }
              />
              <label htmlFor="option2">לא</label>
            </div>
          </div>
        );
      },
    },
  ];

  const refresh = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getProducts(orgId).then((result) => {
      console.log(result);

      setProducts(result);
    });
  };

  const saveRow = (selectedRow: any) => {
    updateProduct(
      selectedRow.runid,
      selectedRow.productId,
      selectedRow.name,
      selectedRow.category,
      selectedRow.location,
      selectedRow.title,
      selectedRow.longTitle,
      selectedRow.description,
      selectedRow.price,
      decodeURI(selectedRow.finalUrl).replaceAll(" ", "_")
    )
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה עודכנה בהצלחה");
          refresh();
        } else {
          toast.warning("לא נמצאו רשומות לעדכון");
        }
      })
      .catch(() => {
        toast.error("שגיאה בעדכון רשומה");
      });
  };
  const inStockChange = (val: string, runid: string) => {
    if (runid == undefined) {
      setInStock(val);
    }
    if (runid != undefined) {
      updateProductInStock(val, runid)
        .then((result) => {
          if (result === "Success") {
            toast.info("רשומה עודכנה בהצלחה");
            refresh();
          } else {
            toast.warning("לא נמצאו רשומות לעדכון");
          }
        })
        .catch(() => {
          toast.error("שגיאה בעדכון רשומה");
        });
    }
  };
  const addRow = (addedRow: any) => {
    console.log(addedRow, inStock);
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    if (
      addedRow.productId == undefined ||
      addedRow.name == undefined ||
      addedRow.category == undefined ||
      addedRow.location == undefined ||
      addedRow.title == undefined ||
      addedRow.longTitle == undefined ||
      addedRow.description == undefined ||
      addedRow.price == undefined ||
      addedRow.finalUrl == undefined
    ) {
      toast.warning(
        "אחד מהשדות ריק, יש למלא את כל השדות הרלוונטיים ליצירת מוצר"
      );
      return;
    }

    addProduct(
      addedRow.productId,
      addedRow.name,
      addedRow.category,
      addedRow.location,
      addedRow.title,
      addedRow.longTitle,
      addedRow.description,
      addedRow.price,
      decodeURI(addedRow.finalUrl).replaceAll(" ", "_"),
      inStock,
      orgId
    )
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה נוספה בהצלחה");
          refresh();
        } else {
          toast.warning("מוצר לא נוצר במערכת, יש לפנות לתמיכה");
        }
      })
      .catch(() => {
        toast.error("שגיאה בהוספת רשומה!!!");
      });
  };
  const deleteRow = (deleteRow: any) => {
    console.log(deleteRow);
    deleteProduct(deleteRow.runid)
      .then((result) => {
        if (result === "Success") {
          toast.info("רשומה נמחקה בהצלחה");
          refresh();
        } else {
          toast.warning("מוצר לא נמחק, יש לפנות לתמיכה");
        }
      })
      .catch(() => {
        toast.error("שגיאה במחיקת רשומה!!!");
      });
  };
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const workbook = read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = utils.sheet_to_json(sheet);
      console.log(sheetData);
      //todo-validate data
      setData(sheetData);
    };

    //reader.readAsBinaryString(file);
    reader.readAsArrayBuffer(file);
  };
  const loadFromExel = () => {
    if (data == null || data == "") {
      toast.info("לא נבחר קובץ.");
      return;
    }
    const str = JSON.stringify(data);
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    loadProducts(str, orgId)
      .then((result) => {
        console.log(result);
        if (result !== 0) {
          toast.info(result + " רשומות נוספו בהצלחה!");
          refresh();
        } else {
          toast.warning("רשומות לא נוספו, יש לפנות לתמיכה");
        }
      })
      .catch(() => {
        toast.error("שגיאה בהוספת רשומות!!!");
      });
  };
  const uploadProduct = () => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    //return;
    if (selectedProduct && selectedProduct.name && selectedProduct.finalUrl) {
      addCampaign(
        orgId,
        selectedAccount,
        selectedProduct.runid,
        selectedProduct.name,
        0,
        1,
        selectedProduct.category,
        selectedProduct.location,
        selectedProduct.title,
        selectedProduct.longTitle,
        selectedProduct.description,
        selectedProduct.price,
        decodeURI(selectedProduct.finalUrl).replaceAll(" ", "_"),
        selectedProduct.squareImage,
        selectedProduct.marketingImage,
        selectedProduct.logoImage,
        selectedProduct.accentColor,
        selectedProduct.mainColor
      )
        .then((result) => {
          if (result !== 0) {
            //שליחת בקשה חדשה
            insertRequestToChangeStatusCampaign(
              orgId,
              typeof result === "number" ? result.toString() : "0",
              1
            )
              .then((result) => {
                if (result === "Success") {
                  insertRequestToProduct(orgId, selectedProduct.runid, 5)
                    .then((result) => {
                      if (result === "Success") {
                        toast.info("קמפיין נוסף בהצלחה");
                        setChooseAccountShow(false);
                        refresh();
                      } else {
                        toast.warning(
                          "רשומה להעלאת מוצר לא נוספה, יש לפנות לתמיכה"
                        );
                      }
                    })
                    .catch(() => {
                      toast.error("שגיאה בהעלאת מוצר!!!");
                    });
                } else {
                  toast.warning("בקשה לא נשלחה, יש לפנות לתמיכה");
                }
              })
              .catch(() => {
                toast.error("שגיאה בשליחת בקשה!!!");
              });
          } else {
            toast.warning("קמפיין לא נוצר במערכת, יש לפנות לתמיכה");
          }
        })
        .catch(() => {
          toast.error("שגיאה ביצירת קמפיין!!!");
        });
    } else {
      toast.warning("לא ניתן להקים קמפיין, ישנם שדות חובה לא מלאים.");
      return;
    }
  };
  const stopProduct = (row: any) => {
    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    checkIfHasRequestToProduct(row.runid, 6)
      .then((result) => {
        if (result == 0) {
          insertRequestToProduct(orgId, row.runid, 6)
            .then((result) => {
              if (result === "Success") {
                toast.info("רשומה לעצירת מוצר נוספה בהצלחה!");
                refresh();
              } else {
                toast.warning("רשומה לעצירת מוצר לא נוספה, יש לפנות לתמיכה");
              }
            })
            .catch(() => {
              toast.error("שגיאה בעצירת מוצר!!!");
            });
        } else {
          toast.error("ישנה בקשה ממתינה לעצירת מוצר.");
        }
      })
      .catch(() => {
        toast.error("שגיאה בעצירת מוצר!!!");
      });
  };

  useEffect(() => {
    refresh();

    const orgId = authValues && authValues.OrgId ? authValues.OrgId : "";
    getAccounts(orgId).then((result) => {
      setAccounts(result);
    });
  }, []);

  return (
    <CustomPage
      headerParameters={headerProps}
      pageName={"Products"}
      title="עריכת מוצרים"
    >
      <IonGrid>
        {/* <IonCard> */}
        <IonRow>
          <input
            type="file"
            onChange={handleFileUpload}
            className="fileButton"
          />
          <IonButton onClick={loadFromExel}>טען מוצרים</IonButton>
        </IonRow>
        {authValues && authValues.Id && (
          <CustomMaterialTable
            rows={products}
            columns={columns}
            //onSearchClick={(startDate: Date, endDate: Date) => {}}
            title={"מוצרים"}
            loaderDuration={3000}
            isDateRange={false}
            isEditable={true}
            onEdit={saveRow}
            onAdd={addRow}
            // onDelete={deleteRow}
            isFilterable={false}
            isDeleteHidden={true}
          />
        )}
        {/* </IonCard> */}
      </IonGrid>
      <CustomModal
        pageName="chooseAccount"
        headerParameters={headerParameters}
        closeModal={() => {
          setChooseAccountShow(false);
        }}
        isOpen={chooseAccountShow}
        title="בחר חשבון עבור המוצר"
      >
        <CustomForm onSubmit={handleSubmit(uploadProduct)} errors={errors}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem mode="md" color={"light"} lines="none">
                  <IonLabel slot="start">חשבון</IonLabel>
                  <IonSelect
                    slot="start"
                    defaultValue={-1}
                    placeholder="בחר חשבון"
                    onIonChange={(e) => {
                      setSelectedAccount(e.detail.value);
                    }}
                    value={selectedAccount}
                    interface="action-sheet"
                    style={{ minWidth: "75%" }}
                    name="accountId"
                  >
                    {accounts &&
                      accounts.map((account: any) => {
                        return (
                          <IonSelectOption key={account.id} value={account.id}>
                            {account.name}
                          </IonSelectOption>
                        );
                      })}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="actions-wrapper" style={{ position: "unset" }}>
              <IonCol>
                <CustomPublishButton
                  expand="block"
                  className="main-button"
                  title="העלאה מוצר"
                  disabled={selectedAccount == ""}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </CustomForm>
      </CustomModal>
    </CustomPage>
  );
};

export default Products;
