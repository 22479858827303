import React, { useEffect, useState, forwardRef, useContext } from "react";
import MaterialTable, { Action, MTableToolbar } from "@material-table/core";
import "./table.scss";
import CustomLoader from "../CustomLoader";
import SummaryTable, { summaryData } from "../../SummaryTable/SummaryTable";
import { ExportCsv } from "@material-table/exporters";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import Search from "@material-ui/icons/Search";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
// import id from "date-fns/esm/locale/id/index.js";
import { isPlatform } from "@ionic/core";
import { isMobile } from "react-device-detect";
import CustomModal from "../CustomModal/CustomModal";
import { HeaderParameters } from "../../../../data/Header-Context";
import CustomDateRangePicker from "../CustomDateRangePicker/CustomDateRangePicker";
import VendorDetailsContext from "../../../../data/VendorDetails-context";
import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import { getDateFormat } from "../../../../utils-ts/GlobalDateFunctions";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";

interface GridActions extends Action<object> {
  icon: string | (() => React.ReactElement<any>);
  tooltip: string;
  btnId?: string;
  onClick: (event: any, rowData: any) => void;
}

interface DataGridProps {
  onDateChange?: (startDate: Date, endDate: Date) => void;
  onSearchClick?: (startDate: Date, endDate: Date) => void;
  onDelete?: (rowData: any) => void;
  onEdit?: (rowData: any) => void;
  onAdd?: (rowData: any) => void;
  gridActivities?: GridActions[];
  startDate?: Date;
  endDate?: Date;
  isDateRange?: boolean;
  isChangDefualteDate?: boolean;
  dateType?: string;
  isEditable?: boolean;
  isFilterable?: boolean;
  isDeleteHidden?: boolean;
  summaryTbl?: { data: any; summaryType?: string };
  limitedDates?: number;
  rows: any;
  columns: any;
  title?: string;
  loaderDuration?: number;
  pageSize?: number;
  className?: string;
}

const tableIcons = {
  DetailPanel: forwardRef<any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
  LastPage: forwardRef<any>((props, ref) => <FirstPage {...props} ref={ref} />),
  FirstPage: forwardRef<any>((props, ref) => <LastPage {...props} ref={ref} />),
  PreviousPage: forwardRef<any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  NextPage: forwardRef<any>((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};

const localization = {
  pagination: {
    labelDisplayedRows: "{from}-{to} מתוך {count}",
    labelRowsSelect: "שורות",
    labelRowsPerPage: "שורות לדף",
    firstTooltip: "דף ראשון",
    previousTooltip: "דף קודם",
    nextTooltip: "דף הבא",
    lastTooltip: "דף אחרון",
  },

  toolbar: {
    nRowsSelected: "{0} שורה(ות) נבחרו",
    exportTitle: "יצא",
    exportName: "יצא כ csv",
    exportCSVName: "csvיצא כ",
    exportPDFName: "pdfיצא כ",
  },
  header: {
    actions: "",
  },
  body: {
    emptyDataSourceMessage: "לא נמצאו רשומות",
    filterRow: {
      filterTooltip: "סנן",
    },
    editTooltip: "ערוך שורה",
    deleteTooltip: "מחיקת שורה",
    addTooltip: "הוסף שורה",
    editRow: {
      saveTooltip: "שמור שינויים",
      deleteText: "האם למחוק את השורה סופית?",
      cancelTooltip: "ביטול שינויים",
      deleteTooltip: "מחק",
    },
  },
};

const CustomMaterialTable: React.FC<DataGridProps> = ({ ...props }) => {
  const [sums, setSums] = useState<{
    data: summaryData[];
    summaryType?: string;
  }>();
  const [isDateRange, setDateRange] = useState<Boolean>(true);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    props.startDate ? props.startDate : new Date()
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(
    props.endDate ? props.endDate : new Date()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<GridActions[]>([]);
  const [rowsData, setData] = useState([]);
  const [isDateRangeShow, setDateRangeShow] = useState<boolean>(false);
  CustomMaterialTable.defaultProps = {
    isDateRange: true,
  };

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };
  const VendorDetailsCxt = useContext(VendorDetailsContext);
  const space = !isMobile ? 450 : 190;
  const pageNum: number = props.pageSize ? props.pageSize : 5;

  const screenHeight = window.screen.availHeight - space;

  useEffect(() => {
    setData(props.rows);
    if (props.rows.length > 0) {
      setCols();
      setLoading(false);
    }
    setDateRange(props.isDateRange ? props.isDateRange : false);
  }, [props.rows]);

  useEffect(() => {
    props.summaryTbl && setSums(props.summaryTbl);
    const tempActivities: GridActions[] = [
      {
        icon: "refresh",
        tooltip: "עדכן טבלה",
        isFreeAction: true,
        onClick: () => {
          setLoading(true);
          props.onSearchClick &&
            props.onSearchClick(selectedStartDate, selectedEndDate);
        },
      },
    ];
    if (props.gridActivities) {
      props.gridActivities.forEach((activity: GridActions) => {
        tempActivities.push({
          icon: activity.icon,
          tooltip: activity.tooltip,
          btnId: activity.btnId,
          onClick: (event: any, rowData: any) => {
            activity.onClick(rowData, activity.btnId);
          },
        });
      });
    }
    setActivities(tempActivities);
  }, [props.gridActivities, props.summaryTbl]);

  useEffect(() => {
    props.onSearchClick &&
      props.onSearchClick(selectedStartDate, selectedEndDate);
  }, [selectedStartDate, selectedEndDate]);

  const setCols = () => {
    props.columns.forEach((col: any) => {
      col.filterPlaceholder = "סנן";
      col.align = "right";
    });
  };

  return (
    <React.Fragment>
      <div
        className={`${props.className}
          ${
            props.gridActivities || props.isEditable
              ? "table-container with-actions"
              : "table-container"
          }
        `}
      >
        <MaterialTable
          title={props.title}
          columns={props.columns}
          data={rowsData}
          localization={localization}
          icons={tableIcons}
          actions={activities}
          options={{
            pageSizeOptions: [5, 10, 20, 50, 100, props.rows.length],
            pageSize: pageNum,
            exportMenu: [
              {
                label: "csvיצא ל",
                exportFunc: (cols, datas) =>
                  ExportCsv(cols, datas, `${props.title}`),
              },
            ],
            exportAllData: true,
            tableLayout: "auto",
            filtering: props.isFilterable ?? true,
            hideFilterIcons: false,
            search: false,
            showTitle: false,
            draggable: false,
            addRowPosition: "first",
            maxBodyHeight: `${screenHeight}px`,
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            editCellStyle: { textAlign: "right" },
            headerStyle: {
              position: "sticky",
              top: 0,
              textAlign: "right",
            },
            filterRowStyle: {
              position: "sticky",
              top: 20,
              background: "white",
              zIndex: 5 /* optionally */,
            },
          }}
          components={{
            Toolbar: ({ ...props }) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    padding: "0px 10px",
                    position: "absolute",
                    top: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2 className="card-title large-title">{props.title}</h2>
                  {
                    isDateRange !== false ? (
                      <IonButton
                        size="small"
                        className="date-range-picker-btn"
                        onClick={() => {
                          setDateRangeShow(true);
                        }}
                      >
                        <IonIcon
                          size="small"
                          icon={calendarOutline}
                          slot="end"
                        />
                        <IonLabel>
                          {getDateFormat(selectedEndDate)} -
                          {getDateFormat(selectedStartDate)}
                        </IonLabel>
                      </IonButton>
                    ) : (
                      ""
                    )
                    //(
                    // <div className="date-picker date-picker-btn">
                    //   <CustomDatePicker
                    //     InputProps={{ readOnly: true }}
                    //     value={selectedStartDate}
                    //     onChange={(date: any) => {
                    //       setSelectedStartDate(date);
                    //       // props.onSearchClick(date);
                    //     }}
                    //   ></CustomDatePicker>
                    // </div>
                    //)
                  }
                </div>
                <div className="card-item-divider"></div>
                {sums?.data && <SummaryTable summaryTbl={sums} />}
              </div>
            ),
          }}
          editable={
            props.isEditable
              ? {
                  isDeleteHidden: (rowData) => props.isDeleteHidden ?? false,
                  onRowUpdate: (newData: never) =>
                    new Promise<void>((resolve, reject) => {
                      setTimeout(() => {
                        props.onEdit && props.onEdit(newData);
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: (oldData: any) =>
                    new Promise<void>((resolve, reject) => {
                      setTimeout(() => {
                        props.onDelete && props.onDelete(oldData);
                        resolve();
                      }, 1000);
                    }),
                  onRowAdd: props.onAdd
                    ? (newData: any) =>
                        new Promise<void>((resolve, reject) => {
                          setTimeout(() => {
                            props.onAdd && props.onAdd(newData);
                            resolve();
                          }, 1000);
                        })
                    : undefined,
                }
              : undefined
          }
        />
        <CustomLoader
          isOpen={loading}
          onDidDismiss={() => {
            setLoading(false);
          }}
          duration={props.loaderDuration ? props.loaderDuration : 4000}
        ></CustomLoader>
      </div>

      <CustomModal
        pageName={"DateRangePicker"}
        headerParameters={headerParameters}
        closeModal={() => {
          setDateRangeShow(false);
        }}
        isOpen={isDateRangeShow}
        title="בחר טווח תאריכים"
      >
        <CustomDateRangePicker
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onSearchClick={(startDate: Date, endDate: Date) => {
            setDateRangeShow(false);
            setLoading(true);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            props.onSearchClick && props.onSearchClick(startDate, endDate);
            if (props.isChangDefualteDate) {
              VendorDetailsCxt.setSelectedDate(startDate, endDate);
            }
          }}
        ></CustomDateRangePicker>
      </CustomModal>
    </React.Fragment>
  );
};

export default CustomMaterialTable;
